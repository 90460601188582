/* BlogSummary.css */
.blog-summary-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .blog-content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  
  .blog-image {
    flex: 1;
    width: 100%;
    max-width: 350px;
    height: auto;
    border-radius: 10px;
  }
  
  .blog-text-wrapper {
    flex: 2;
  }
  
  .blog-title {
    color: #16757D;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .blog-date {
    color:white;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .blog-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .load-more-button,
  .load-less-button {
    background-color: #16757D;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
  }
  
  .load-more-button:hover,
  .load-less-button:hover {
    background-color: #135a5f;
  }
  
  .blog-full-content {
    margin-top: 20px;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .blog-content-wrapper {
      flex-direction: column;
    }
  
    .blog-image {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  